import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String,
  };

  async updateEsocial() {
    try {
      document.getElementById("esocial-status-text").textContent = "Validando...";
      const response = await fetch(this.urlValue, {
        method: "PATCH",
        headers: {
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
          "Content-Type": "application/json",
          Accept: "text/vnd.turbo-stream.html",
        },
      });

      if (!response.ok) {
        document.getElementById("esocial-status-text").textContent = "Erro ao validar e-Social";
        return;
      }

      const html = await response.text();
      Turbo.renderStreamMessage(html);
    } catch (error) {
      document.getElementById("esocial-status-text").textContent = "Erro ao atualizar os dados do e-Social.";
    }
  }
}
