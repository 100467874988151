// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from "./application"

// Register each controller with Stimulus
import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})

import { Alert, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('alert', Alert)
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)

import Flatpickr from 'stimulus-flatpickr'
application.register('flatpickr', Flatpickr)

import ModalController from "./modal_controller.js"
application.register("modal", ModalController)

import DocumentGroupController from "./document_group_controller.js"
application.register("document_group", DocumentGroupController)

import DocumentController from "./document_controller.js"
application.register("document", DocumentController)

import WorkplaceController from "./workplace_controller.js"
application.register("workplace", WorkplaceController)

import CNPJController from "./cnpj_controller"
application.register("cnpj", CNPJController)

import SidebarController from "./sidebar_controller.js"
application.register("sidebar", SidebarController)

import DropdownController from "./dropdown_controller.js"
application.register("dropdown", DropdownController)