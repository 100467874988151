import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu", "arrow"]

  connect() {
    this.isOpen = false
    this.updateArrowRotation()
  }

  disconnect() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  }

  toggle(event) {
    event.stopPropagation()
    event.preventDefault()

    this.isOpen = !this.isOpen

    if (this.isOpen) {
      this.show()
    } else {
      this.hide()
    }
  }

  show() {
    if (!this.hasMenuTarget) return

    document.querySelectorAll('[data-controller="dropdown"]').forEach(dropdown => {
      if (dropdown !== this.element) {
        const controller = this.application.getControllerForElementAndIdentifier(dropdown, 'dropdown')
        if (controller) controller.hide()
      }
    })

    const buttonRect = this.element.getBoundingClientRect()
    const menu = this.menuTarget

    menu.classList.remove('hidden')

    this.positionMenu(buttonRect)

    requestAnimationFrame(() => {
      menu.classList.add('opacity-100')
      menu.classList.remove('opacity-0')
      menu.classList.remove('scale-95')
      menu.classList.add('scale-100')
    })

    this.updateArrowRotation()
  }

  hide(event) {
    if (event && this.element.contains(event.target)) return

    this.isOpen = false
    const menu = this.menuTarget

    menu.classList.remove('opacity-100')
    menu.classList.add('opacity-0')
    menu.classList.remove('scale-100')
    menu.classList.add('scale-95')

    setTimeout(() => {
      menu.classList.add('hidden')
    }, 200)

    this.updateArrowRotation()
  }

  positionMenu(buttonRect) {
    const menu = this.menuTarget
    const isMobile = window.innerWidth < 768

    if (isMobile) {
      menu.style.width = 'auto'
    } else {
      menu.style.width = '14rem'
    }

    menu.classList.add('transform', 'transition-all', 'duration-200', 'ease-in-out')
  }

  updateArrowRotation() {
    if (this.hasArrowTarget) {
      this.arrowTarget.style.transform = this.isOpen ? 'rotate(180deg)' : ''
    }
  }

  initialize() {
    document.addEventListener('click', this.hide.bind(this))

    this.resizeObserver = new ResizeObserver(() => {
      if (this.isOpen) {
        const buttonRect = this.element.getBoundingClientRect()
        this.positionMenu(buttonRect)
      }
    })

    this.resizeObserver.observe(document.body)
  }
}