import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["costCenter"];

  updateCostCenter(event) {
    const workplaceId = event.target.value;

    if (workplaceId) {
      fetch(`/job_positions/fetch_cost_center?workplace_id=${workplaceId}`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Failed to fetch cost center");
        })
        .then((data) => {
          this.costCenterTarget.textContent = data.cost_center || "N/A";
        })
        .catch((error) => {
          this.costCenterTarget.textContent = "N/A";
        });
    } else {
      this.costCenterTarget.textContent = "N/A";
    }
  }
}
