import { Application } from "@hotwired/stimulus"
import WorkplaceController from "./workplace_controller";


const application = Application.start()
application.register("workplace", WorkplaceController);

// Configure Stimulus development experience
application.debug    = false
window.Stimulus      = application

export { application }
