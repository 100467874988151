import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["tabContent"];

    selectTab(event) {
        event.preventDefault();
        const target = event.currentTarget.dataset.tabTarget;


        const tabs = this.element.querySelectorAll(".tab");
        tabs.forEach(tab => {
            tab.classList.remove("text-orange-600");
            tab.classList.add("text-gray-600");
        });


        event.currentTarget.classList.remove("text-gray-600");
        event.currentTarget.classList.add("text-orange-600");


        this.tabContentTargets.forEach(content => {
            content.classList.add("hidden");
        });


        document.querySelector(target).classList.remove("hidden");
    }
}
