document.addEventListener("turbo:load", function () {
    document.querySelectorAll(".toggle-panel-button").forEach(function (button) {
        button.addEventListener("click", function () {
            const panelContent = this.closest(".panel-container").querySelector(".panel-content");
            panelContent.classList.toggle("hidden");
        });
    });
});

document.addEventListener("turbo:load", function () {
    document.querySelectorAll(".options-icon").forEach(function (icon) {
        icon.addEventListener("click", function () {
            const card = this.closest(".relative");
            const descriptionText = card.querySelector(".description-text");
            const optionsPanel = card.querySelector(".options-panel");

            descriptionText.classList.toggle("hidden");
            optionsPanel.classList.toggle("visible");
        });
    });
});

document.addEventListener("turbo:load", function () {
    const menuToggleButton = document.getElementById("menu-toggle");
    const sidebar = document.getElementById("sidebar");
    const mainContent = document.getElementById("main-content");
    if (menuToggleButton && sidebar && mainContent) {
        menuToggleButton.addEventListener("click", function () {
            sidebar.classList.toggle("collapsed");
            mainContent.classList.toggle("ml-16");
            mainContent.classList.toggle("ml-64");
        });
    }
});