import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["group", "category", "documentType"]

  connect() {
    this.groupTarget.addEventListener("change", this.handleGroupChange.bind(this))
    this.categoryTarget.addEventListener("change", this.updateDocumentTypes.bind(this))
  }

  handleGroupChange() {
    const groupId = this.groupTarget.value;

    if (!groupId) {
      this.resetSelectOptions(this.categoryTarget, "Selecione uma categoria");
      this.resetSelectOptions(this.documentTypeTarget, "Selecione um tipo de documento");
      return;
    }

    const groupName = this.groupTarget.options[this.groupTarget.selectedIndex].text;

    if (["CLT", "PJ", "Dependentes"].includes(groupName)) {
      this.updateCategories();
    } else {
      this.updateDocumentTypes();
    }
  }

  updateCategories() {
    const groupId = this.groupTarget.value;
  
    this.resetSelectOptions(this.categoryTarget, "Carregando...");
    this.resetSelectOptions(this.documentTypeTarget, "Carregando...");
  
    fetch(`/documents/get_categories?group_id=${groupId}`)
      .then(response => response.json())
      .then(data => {
        this.populateSelectOptions(this.categoryTarget, data.categories, "Nenhuma categoria disponível");
  
        if (data.categories.length === 0) {
          this.updateDocumentTypes();
        } else {
          this.categoryTarget.dispatchEvent(new Event('change'));
        }
      })
      .catch(error => this.showError(this.categoryTarget, "Erro ao carregar categorias", error));
  }

  updateDocumentTypes() {
    const groupId = this.groupTarget.value;
    const categoryId = this.categoryTarget.value;

    this.resetSelectOptions(this.documentTypeTarget, "Carregando...");

    const url = categoryId ? 
      `/documents/get_document_types?category_id=${categoryId}&group_id=${groupId}` :
      `/documents/get_document_types?group_id=${groupId}`;

    fetch(url)
      .then(response => response.json())
      .then(data => {
        this.populateSelectOptions(this.documentTypeTarget, data.document_types, "Nenhum tipo de documento disponível");
      })
      .catch(error => this.showError(this.documentTypeTarget, "Erro ao carregar tipos de documentos", error));    
  }

  populateSelectOptions(selectElement, items, emptyMessage) {
    if (!selectElement) {
      console.error("Elemento select não encontrado");
      return;
    }

    selectElement.innerHTML = items.length
      ? items.map(item => `<option value="${item.id}">${item.name}</option>`).join('')
      : `<option value="">${emptyMessage}</option>`;
  }

  resetSelectOptions(selectElement, placeholder) {
    if (selectElement) {
      selectElement.innerHTML = `<option value="">${placeholder}</option>`;
    }
  }

  showError(selectElement, message, error = null) {
    if (selectElement) {
      selectElement.innerHTML = `<option value="">${message}</option>`;
    }
    console.error("Erro:", error);
  }
}
