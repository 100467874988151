import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
    static targets = [
        "postalCode", "line1", "line2", "neighborhood",
        "city", "state", "country", "latitude", "longitude"
    ];
    static values = {
        url: String,
        param: String
    };

    change(event) {
        const selectedState = { "uf": this.getSelectedState(event), "localidade": "" };
        this.fetchCities(selectedState);
    }

    lookupAddress() {
        const postalCode = this.postalCodeTarget.value;

        if (this.isValidPostalCode(postalCode)) {
            this.fetchAddress(postalCode)
                .then(data => this.handleAddressData(data))
                .catch(error => this.handleFetchError(error));
        }
    }


    async fetchCities(data) {
        this.showLoadingIndicator();
        const params = this.createParams(data.uf);
        try {
            await get(`/addresses/cities?${params}`, { responseKind: "turbo-stream" });
            await new Promise(requestAnimationFrame);
            this.selectCity(data.localidade);

        } catch (error) {
            console.error("Error loading cities:", error);
        }
    }

    showLoadingIndicator() {
        this.cityTarget.innerHTML = '<option>Carregando...</option>';
    }

    selectCity(city) {
        const option = Array.from(this.cityTarget.options).find(opt => opt.value == city);
        if (option) {
            option.selected = true;
        }
    }

    createParams(state) {
        const params = new URLSearchParams();
        params.append("state", state);
        params.append("target", this.cityTarget.id);
        return params;
    }

    async fetchAddress(postalCode) {
        const response = await fetch(`/addresses/fetch_address?postal_code=${postalCode}`);
        return response.json();
    }

    async handleAddressData(data) {
        if (!data.erro) {
            this.setAddressFields(data);
            await this.fetchCities(data);


        } else {
            alert("CEP not found.");
        }
    }

    setAddressFields(data) {
        this.line1Target.value = data.logradouro;
        this.neighborhoodTarget.value = data.bairro;
        this.stateTarget.value = data.uf;
        this.countryTarget.value = "Brasil";
    }

    isValidPostalCode(postalCode) {
        return postalCode.length === 8;
    }

    handleFetchError(error) {
        console.error("Error fetching address:", error);
    }

    ensureSelectId() {
        if (!this.cityTarget.id) {
            this.cityTarget.id = this.generateUniqueId();
        }
    }

    generateUniqueId() {
        return Math.random().toString(36);
    }

    getSelectedState(event) {
        return event.target.selectedOptions[0].value;
    }
}
