import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  openNewGroupModal(event) {
    // event.preventDefault();

    fetch("/document_groups/new", {
      headers: {
        "Turbo-Frame": "modal"
      }
    })
    .then(response => response.text())
    .then(html => {
      const modalElement = document.querySelector("#modal");
      modalElement.innerHTML = html;
      this.showModal(modalElement);
    })
    .catch(error => {
      console.error("Erro ao carregar o modal:", error);
    });
  }

  showModal(modalElement) {
    modalElement.showModal();
    modalElement.classList.remove("hidden");
  }

  closeModal() {
    const modal = document.querySelector("#modal");
    if (modal) {
      modal.classList.add("hidden");
      modal.close();
    }
  }
}
