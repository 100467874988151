import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "sidebarLogoContainer",
    "sidebarWrapper",
    "sidebarWrapper",
    "buttonWrapper",
    "logoContainer",
    "expandedLogo",
    "collapsedLogo",
    "toggleIcon",
    "linkText",
    "tooltip",
    "iconWrapper"
  ]

  connect() {
    this.expanded = localStorage.getItem("sidebarExpanded") !== "false"
    this.setupTooltips()
    this.updateLayout()
  }

  setupTooltips() {
    if (!this.hasTooltipTarget) return

    this.tooltipTargets.forEach(tooltip => {
      const parentLink = tooltip.closest('.group')

      parentLink.addEventListener('mouseenter', () => {
        if (this.expanded) return

        const linkRect = parentLink.getBoundingClientRect()
        this.showTooltip(tooltip, linkRect)
      })

      parentLink.addEventListener('mouseleave', () => {
        this.hideTooltip(tooltip)
      })
    })
  }

  showTooltip(tooltip, linkRect) {
    tooltip.classList.remove('hidden')
    const tooltipRect = tooltip.getBoundingClientRect()

    tooltip.style.top = `${linkRect.top + (linkRect.height / 2) - (tooltipRect.height / 2)}px`
    tooltip.style.left = `${linkRect.right + 8}px`

    requestAnimationFrame(() => {
      tooltip.style.opacity = '1'
      tooltip.style.transform = 'translateX(0) scale(1)'
    })
  }

  hideTooltip(tooltip) {
    tooltip.style.opacity = '0'
    tooltip.style.transform = 'translateX(-10px) scale(0.95)'

    tooltip.addEventListener('transitionend', () => {
      if (tooltip.style.opacity === '0') {
        tooltip.classList.add('hidden')
      }
    }, { once: true })
  }

  updateLayout() {
    this.sidebarWrapperTarget.style.width = this.expanded ? '256px' : '80px'

    this.iconWrapperTargets.forEach(wrapper => {
      wrapper.style.minWidth = '32px'
      wrapper.style.justifyContent = 'center'
    })

    this.sidebarLogoContainerTarget.style.justifyContent = this.expanded ? 'space-between' : 'center'

    this.expandedLogoTarget.style.display = this.expanded ? 'block' : 'none'
    this.collapsedLogoTarget.style.display = this.expanded ? 'none' : 'block'

    this.linkTextTargets.forEach(text => {
      if (this.expanded) {
        text.classList.remove('hidden')
        text.style.opacity = '1'
      } else {
        text.style.opacity = '0'
        text.addEventListener('transitionend', (e) => {
          if (e.propertyName === 'opacity' && !this.expanded) {
            text.classList.add('hidden')
          }
        }, { once: true })
      }
    })

    if (this.hasTooltipTarget) {
      this.tooltipTargets.forEach(tooltip => {
        tooltip.classList.add('hidden')
        tooltip.style.opacity = '0'
      })
    }

    this.toggleIconTarget.style.transform = this.expanded ? 'rotate(0deg)' : 'rotate(180deg)'
    this.dispatch("stateChanged", { detail: { expanded: this.expanded } })
  }

  toggle() {
    this.expanded = !this.expanded
    localStorage.setItem("sidebarExpanded", this.expanded)
    this.updateLayout()
  }
  
  initialize() {
    this.resizeHandler = this.debounce(() => {
      this.updateLayout()

      if (this.hasTooltipTarget) {
        this.tooltipTargets.forEach(tooltip => {
          this.hideTooltip(tooltip)
        })
      }
    }, 100)

    window.addEventListener('resize', this.resizeHandler)
  }

  disconnect() {
    window.removeEventListener('resize', this.resizeHandler)
  }

  debounce(func, wait) {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(this, args), wait)
    }
  }
}