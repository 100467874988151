/* eslint no-console:0 */

import { Turbo } from "@hotwired/turbo-rails"
require("@rails/activestorage").start()
require("local-time").start()

import "./channels"
import "./controllers"
import "./src/**/*"

//= require rails-ujs
//= require jquery

document.addEventListener('turbo:submit-end', (event) => {
  if (event.detail.success) {
    Turbo.visit(event.detail.fetchResponse.response.url);
  }
});
