// app/javascript/controllers/document_field_modal_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "step1", "step2", "step3", "errorContainer", "nextButton"]
  static values = { step: Number }

  connect() {
    this.stepValue = this.stepValue || 1;
    this.updateStepStyles();
    this.loadContent();
  }

  async nextStep(event) {
    event.preventDefault();

    if (this.stepValue === 2) {
      const validation = await this.validateCurrentStep();
      if (validation.isValid) {
        this.clearErrors();
        this.incrementStep();
      } else {
        this.displayErrors(validation.pendingItems || []);
      }
    } else if (this.stepValue < 3) {
      this.clearErrors();
      this.incrementStep();
    } else if (this.stepValue === 3) {
      this.clearErrors();
      this.incrementStep();
    }
  }

  displayErrors(pendingItems) {
    const errorMessages = (pendingItems || []).map(error => `<li>${pendingItems}</li>`).join("");
    this.errorContainerTarget.innerHTML = `<ul>${errorMessages}</ul>`;
    this.errorContainerTarget.classList.remove("hidden");
  }

  clearErrors() {
    this.errorContainerTarget.innerHTML = "";
    this.errorContainerTarget.classList.add("hidden");
  }

  previousStep(event) {
    event.preventDefault();
    if (this.stepValue > 1) {
      this.stepValue -= 1;
      this.updateStepStyles();
      this.updateButtonText();
      this.loadContent();
    }
  }

  incrementStep() {
    this.stepValue += 1;
    this.updateStepStyles();
    this.updateButtonText();
    this.loadContent();
  }

  updateStepStyles() {
    this.resetStepStyles(this.step1Target, 1);
    this.resetStepStyles(this.step2Target, 2);
    this.resetStepStyles(this.step3Target, 3);
  }

  resetStepStyles(stepTarget, stepNumber) {
    const isActive = this.stepValue >= stepNumber;

    stepTarget.classList.toggle("bg-[#E85621]", isActive);
    stepTarget.classList.toggle("bg-gray-100", !isActive);

    const numberSpan = stepTarget.querySelector(".step-number");
    numberSpan.classList.toggle("text-[#E85621]", isActive);
    numberSpan.classList.toggle("text-gray-500", !isActive);

    const textSpan = stepTarget.querySelector(".step-text");
    textSpan.classList.toggle("text-white", isActive);
    textSpan.classList.toggle("text-gray-600", !isActive);

    const mobileTargets = document.querySelectorAll(`.step-mobile-${stepNumber}`);
    mobileTargets.forEach((mobileTarget) => {
      mobileTarget.classList.toggle("text-white", isActive);
      mobileTarget.classList.toggle("bg-[#E85621]", isActive);
      mobileTarget.classList.toggle("text-gray-600", !isActive);
      mobileTarget.classList.toggle("bg-gray-100", !isActive);
    });
  }

  async loadContent() {
    try {
      const response = await fetch(`/candidates/admissions/step/${this.stepValue}`, {
        headers: { "Turbo-Frame": "tabs-content" }
      });
      if (response.redirected) {
        window.location.href = response.url;
      } else {
        const html = await response.text();
        this.contentTarget.innerHTML = html;
      }
    } catch (error) {
      console.error("Error loading content:", error);
    }
  }

  async validateCurrentStep() {
    try {
      const response = await fetch(`/candidates/admissions/validate_step/${this.stepValue}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();
      return { isValid: data.isValid, pendingItems: data.pendingItems || [] };
    } catch (error) {
      console.error("Error validating step:", error);
      return { isValid: false, pendingItems: ["Erro ao validar o passo atual."] };
    }
  }
  updateButtonText() {
    if (this.stepValue === 3) {
      this.nextButtonTarget.textContent = "Finalizar";
    } else {
      this.nextButtonTarget.textContent = "Prosseguir";
    }
  }
}
