import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  uploadFile(event) {
    const fileInput = event.target
    if (fileInput.files.length > 0) {
      this.element.requestSubmit()
    }
  }
}
