import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
    static targets = ["jobPosition", "flow", "branchOffice", "department", "jobShift", "workplace"];


    handleJobPositionChange(event) {
        const jobPositionId = event.target.value;
        if (jobPositionId) {
            this.fetchAdmissionFields(jobPositionId);
        } else {
            this.resetAdmissionFields();
        }
    }

    async fetchAdmissionFields(jobPositionId) {
        try {
            const response = await get(`/job_positions/${jobPositionId}/admission_fields`, { responseKind: "turbo-stream" });
            // Handle the response from the controller
            // For example, if your controller returns JSON, you can do something like this:
            // const data = response.json();
            // this.flowTarget.value = data.flow_id;
            // this.branchOfficeTarget.value = data.branch_office_id;
            // ... update other targets
        } catch (error) {
            console.error("Error fetching admission fields:", error);
        }
    }

    resetAdmissionFields() {
        // Reset the values of all targets that need to be cleared
        this.flowTarget.value = "";
        this.branchOfficeTarget.value = "";
        this.departmentTarget.value = "";
        this.jobShiftTarget.value = "";
        this.workplaceTarget.value = "";
        // ... reset other targets
    }
}
