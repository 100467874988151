// app/javascript/controllers/collapse_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "content", "icon"];


  toggle(event) {
    const content = this.contentTarget;
    const icon = this.iconTarget;

    content.classList.toggle("hidden");


    if (content.classList.contains("hidden")) {
      icon.classList.remove("rotate-180");
    } else {
      icon.classList.add("rotate-180");
    }
  }
}
